import authRoles from '../auth/authRoles';

const navigationConfig = [
	{
		id: 'ameroAdmin',
		title: 'Amero Admin',
		type: 'group',
		icon: 'apps',
		auth: authRoles.ameroAdmin,
		children: [
			{
				id: 'organisations',
				title: 'Organisationer',
				type: 'item',
				icon: 'business',
				url: '/apps/organisations',
				exact: true,
				auth: authRoles.ameroAdmin
			}
		]
	},
	{
		id: 'ameroAdminPrOrganisation',
		title: 'Amero Admin',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.ameroAdmin,
		children: [
			{
				id: 'transaction',
				title: 'Alle ordrer',
				type: 'item',
				icon: 'receipt',
				url: '/apps/orders',
				exact: true,
				auth: authRoles.ameroAdmin
			},
			{
				id: 'import',
				title: 'Importér',
				type: 'item',
				icon: 'import_export',
				url: '/apps/import-jobs',
				exact: true,
				auth: authRoles.ameroAdmin
			}
		]
	},
	{
		id: 'user',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.user,
		children: [
			{
				id: 'booth-overview',
				title: 'Overblik',
				type: 'item',
				icon: 'dashboard',
				url: '/apps/overview',
				exact: true
			},
			{
				id: 'dashboard',
				title: 'Standbelægning',
				type: 'item',
				icon: 'view_module',
				url: '/apps/dashboard',
				exact: true
			},
			{
				id: 'sales',
				title: 'Salg',
				type: 'item',
				icon: 'point_of_sale',
				url: '/apps/sales',
				exact: true
			},
			{
				id: 'boothowners',
				title: 'Standholdere',
				type: 'item',
				icon: 'people',
				url: '/apps/booth-owners',
				exact: true
			},
			{
				id: 'subscriptions',
				title: 'Stande',
				type: 'item',
				icon: 'storefront',
				url: '/apps/booth-subscriptions',
				exact: true
			},
			{
				id: 'products',
				title: 'Produkter',
				type: 'item',
				icon: 'shop_two',
				url: '/apps/products',
				exact: true
			}
		]
	},
	{
		id: 'admin-diverse',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.user,
		children: [
			{
				id: 'reports',
				title: 'Rapporter',
				type: 'item',
				icon: 'library_books',
				url: '/apps/reports',
				auth: authRoles.user,
				exact: true
			}
		]
	},
	{
		id: 'admin',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.admin,
		children: [
			{
				id: 'bankPayouts',
				title: 'Bankudbetalinger',
				type: 'item',
				icon: 'account_balance',
				url: '/apps/bank-payouts',
				exact: true
			},
			{
				id: 'boothTypes',
				title: 'Standtyper',
				type: 'item',
				icon: 'api',
				url: '/apps/booth-types',
				exact: true
			},
			{
				id: 'locations',
				title: 'Lokationer',
				type: 'item',
				icon: 'location_on',
				url: '/apps/locations',
				exact: true
			},
			{
				id: 'vat',
				title: 'Momskoder',
				type: 'item',
				icon: 'business',
				url: '/apps/vat',
				exact: true,
				auth: authRoles.ameroAdmin
			},
			{
				id: 'incrementalNumbers',
				title: 'Fortløbende numre',
				type: 'item',
				icon: 'plus_one',
				url: '/apps/incremental-numbers',
				exact: true,
				auth: authRoles.ameroAdmin
			},
			{
				id: 'communicationTemplates',
				title: 'Kommunikationsskabeloner',
				type: 'item',
				icon: 'mail',
				url: '/apps/communication-templates',
				exact: true,
				auth: authRoles.admin
			}
		]
	}
];

export default navigationConfig;
