import firebaseService from '@ameroservices-platform/shared/services/firebase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import cookie from 'cookiejs';
import { getParameterByName } from '@ameroservices-platform/shared/utility/index';

class LaunchDarkly extends Component {
	state = {
		ldProvider: null,
	};

	componentDidMount() {
		firebaseService.onAuthStateChanged(async (userToken) => {
			if (userToken) {
				const user = await userToken.getIdTokenResult();
				console.log('LD', { user });
				if (
					user &&
					user.claims &&
					user.claims.organisationIds &&
					user.claims.organisationIds[this.props.type]
				) {
					this.ldCheck(user).then((LDProvider) => {
						if (LDProvider) {
							this.setState({ ldProvider: LDProvider });
						}
					});
				}
			}
		});
	}

	ldCheck = async (authUser) => {
		if (this.state.ldProvider) {
			return Promise.resolve();
		}
		console.log('authUser ', authUser);
		const user = {
			key: `frontend-org-${authUser.claims.organisationIds[this.props.type]}`,
			custom: {
				name: 'Generic Frontend User',
				organisationUid: authUser.claims.organisationIds[this.props.type],
				anonymous: authUser.signInProvider === 'anonymous',
				token: getParameterByName('token') || null,
			},
		};
		const options = {};
		console.log('launchDarkly init with user and options: ', user, options);
		const feature = getParameterByName('feature');
		if (feature) {
			cookie.set('ld_feature', feature, { expires: 1 });
		}
		if (feature || cookie.get('ld_feature')) {
			const featureToSet = feature || cookie.get('ld_feature');
			console.log('value set on user ', featureToSet);
			user.custom[`feature-${featureToSet}`] = true;
		}
		const LDProvider = await asyncWithLDProvider({
			clientSideID: process.env.REACT_APP_LD_CLIENTSIDEID || '6096f35fceeceb0de5e990c7',
			user,
			options,
		});
		return LDProvider;
	};

	render() {
		if (this.state.ldProvider) {
			const LDComponent = this.state.ldProvider;
			return <LDComponent>{this.props.children}</LDComponent>;
		}

		return (
			<div id="fuse-splash-screen">
				<div className="center">
					<div className="logo">
						<img width="128" src="assets/images/logos/amero.png" alt="logo" />
					</div>
					<div className="spinner-wrapper">
						<div className="spinner">
							<div className="inner">
								<div className="gap" />
								<div className="left">
									<div className="half-circle" />
								</div>
								<div className="right">
									<div className="half-circle" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ auth }) {
	return {
		user: auth.user,
	};
}

export default connect(mapStateToProps)(LaunchDarkly);
